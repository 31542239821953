export default [
  {
    categoryName: 'Dashboard',
    items: [
      {
        itemName: 'Dashboard',
        itemLink: '/admin/dashboard',
      },
    ],
  },
]
